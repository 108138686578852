import styles from "./App.module.css";
import Calendar from "react-calendar";
import Billing from "./components/Billing";
import { BrowserRouter, Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Admin from "./components/Admin";
import AdminLogin from "./components/AdminLogin";
import PrintedForm from "./components/PrintedForm";
import { useEffect } from "react";


function App() {
  const getToken = () => {
    return localStorage.getItem("token") || null;
  };

  const isToken = !!getToken();

  return (
    <div>
      <BrowserRouter>
        <Routes>
          {isToken ? (
            <>
              <Route path="/print" element={<PrintedForm />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/" element={<Billing />} />
              <Route path="/*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route path="/*" element={<Navigate to="/login" />} />
              <Route exact path="/login" element={<AdminLogin />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

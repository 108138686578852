import axios from "axios";
import React, { useState } from "react";
import styles from "./AdminLogin.module.css";

function AdminLogin() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async () => {
    try {
      if (!username || !password) {
        setError("Username and password are required.");
        return;
      }

      const response = await axios.post(
        "https://school-hygiene.codebucketstage.online/api/v1/auth/login",
        {
          username: username,
          password: password,
        }
      );

      if (response.status === 200) {
        // Login successful
        const { token, user } = response.data;       
        localStorage.setItem("token", token);
        window.location.replace("/");
      } else {
        setError("Invalid username or password.");
      }
    } catch (error) {
      console.error("Error during login:", error.message);
      setError("An error occurred during login. Please try again.");
    }
  };

  return (
    <div className={styles.mainContainerLogin}>
      <h3>Login Form</h3>
      <div className={styles.LoginFormContainer}>
        <div className={styles.inputValuesUser}>
          <div className={styles.UsernameUSER}>
            <label htmlFor="username">
              username<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="text"
              placeholder="username"
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className={styles.UserPasswordUser}>
            <label htmlFor="password">
              password<span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="password"
              placeholder="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className={styles.UserLoginBtn}>
            <button onClick={handleLogin}>login</button>
          </div>
        </div>
        <div className={styles.ForgetPasswordUser}>
          <p>Don't have an account</p>
          <p>Forget Password?</p>
        </div>
        {error && <p className={styles.error}>{error}</p>}
      </div>
    </div>
  );
}

export default AdminLogin;

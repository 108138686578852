import React, { useEffect, useState, useRef } from "react";
import styles from "./PrintedForm.module.css";
import { getDaysInMonth } from "date-fns";
import { TfiCheck, TfiClose } from "react-icons/tfi";
import axios from "axios";
import generatePDF, { Resolution, Margin } from "react-to-pdf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";


const getTargetElement = () => document.getElementById("content-id");

function PrintedForm() {

  const location = useLocation();
  const { schoolId: navSchoolId, inputDate: navInputDate, navDaysInMonth } = location.state || {};



  const [schoolData, setSchoolData] = useState({
    schoolName: "",
    districtName: "",
    blockName: "",
    attendance: [],
  });
  const [errorId, setErrorId] = useState("");
  const [attendanceData, setAttendanceData] = useState([]);
  const targetRef = useRef();
  const [errorMonth, setErrorMonth] = useState("");
  const [schoolId, setSchoolId] = useState(navSchoolId || "");
  const [inputDate, setInputDate] = useState(navInputDate || "");
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const calendarRef = useRef();

  useEffect(() => {
    if ((navSchoolId && navInputDate) || (inputDate && schoolId.length === 11)) {
      // setSchoolId(navSchoolId)
      // setInputDate(navInputDate)
      handleSearch()
    }
  }, [navSchoolId, navInputDate, inputDate, schoolId])

  const [attendaceData1, setAttendanceData1] = useState([]);
  console.log("🚀 ~ file: PrintedForm.js:51 ~ PrintedForm ~ attendaceData1:", attendaceData1)


  const downloadPDF = () => {

    if (schoolId && inputDate && schoolData.schoolName) {
      const fileName = `${schoolId}_${schoolData.schoolName}_${inputDate}`;

      const options = {
        page: {
          margin: Margin.SMALL,
          format: 'A4',
          // default is 'portrait'
          orientation: 'landscape',
        },
        filename: fileName
      };
      generatePDF(getTargetElement, options);
    } else {
      toast.error('schoolId and inputDate missing');
    }
  };

  const currentMonthDays = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  ).getDate();
  const [daysInMonth, setDaysInMonth] = useState(currentMonthDays);

  useEffect(()=>{

    if(navDaysInMonth){
      setDaysInMonth(navDaysInMonth)
    }

  }, [navDaysInMonth])

  const handleCalendarChange = (date) => {
    if (date === null) {
      return;
    }
    if (date === null) {
      setInputDate("");
      setDaysInMonth(0);
    } else {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const dateString = `${year}-${month.toString().padStart(2, "0")}`;
      setInputDate(dateString);
      const newDate = new Date(year, month - 1, 1);
      const newDaysInMonth = new Date(year, month, 0).getDate();
      setDaysInMonth(newDaysInMonth);
    }
  };

  const handleCalendarIconClick = () => {
    setCalendarOpen(!isCalendarOpen);
  };

  const getAttendanceToPrint = async () => {
    try {
      const response = await axios.get(
        `https://school-hygiene.codebucketstage.online/api/v1/school/${schoolId}`
      );
      setSchoolData(response.data.data);
      setErrorMonth("");
      setErrorId("");
      // Fetch existing attendance data for the selected month and school
      const token = localStorage.getItem("token");
      console.log(token);
      const existingDataResponse = await axios.get(
        `https://school-hygiene.codebucketstage.online/api/v1/attendance/${schoolId}?billingMonth=${inputDate}`,
        {
          headers: {
            token: token,
          },
        }
      );

      const data = existingDataResponse.data.data.attendance.map((a, i) => {
        return {
          day: Number(a.day.split("-")[2]),
          signature: a.signature,
          noOfToilet: a.noOfToilet,
        };
      });
      setAttendanceData1(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleSearch = async () => {
    if (!inputDate) {
      setErrorMonth("Please select Billing Month");
      return;
    }
    if (!schoolId) {
      setErrorId("Please enter School ID ");
      return;
    }
    getAttendanceToPrint()

  };
   

  return (
    <>
      <Toaster />

      <div className={styles.PDFFULL}>

        <div className={styles.Calender}>
          <h4>Billing Month :</h4>

          <div className={styles.calendarIcon} >
            <div className={styles.calendarDropdown} ref={calendarRef}>
              <DatePicker
                onChange={handleCalendarChange}
                selected={inputDate ? new Date(inputDate) : null}
                dateFormat="yyyy/MM"
                showMonthYearPicker
                onFocus={() => setCalendarOpen(true)}
                onClickOutside={() => setCalendarOpen(false)}
                open={isCalendarOpen}
              />
            </div>
          </div>
          <FaCalendarAlt
            onClick={handleCalendarIconClick}
            style={{ cursor: "pointer" }}
          />
          {errorMonth ? (
            <span style={{ color: "red" }}>Please select Month and Year</span>
          ) : (
            ""
          )}
        </div>
        <div className={styles.Schoolid}>
          <h4>School ID : </h4>
          <input
            type="text"
            value={schoolId}
            onChange={(e) => setSchoolId(e.target.value)}
          />
          <button type="button" onClick={handleSearch}>
            search
          </button>
          {errorId ? (
            <span style={{ color: "red" }}>Please Enter school Id</span>
          ) : (
            ""
          )}
          <div className={styles.btn}>
            <button
              onClick={downloadPDF}>
              Download PDF
            </button>
            
          </div>
        </div>

        <div className={styles.mainContainer} ref={targetRef} id="content-id">
          <div className={styles.heading}>
            <h3>
              HOUSEKEEPING REPORT OF M/s CODEBUCKET SOLUTIONS PRIVATE LIMITED
            </h3>
          </div>
          <div className={styles.BillingMonth}>
            <p>Billing Month & Year:</p>
            <span>{inputDate}</span>
          </div>
          <div className={styles.schoolId}>
            <p>School Id:</p> <span> {schoolId}</span>
          </div>
          <div className={styles.SchoolName}>
            <p>School Name: </p>
            <span>{schoolData.schoolName}</span>
          </div>
          <div className={styles.distBlock}>
            <p>Distt. & Block: </p>
            <span>
              {schoolData.districtName}, {schoolData.blockName}
            </span>
          </div>

          <div className={styles.housekeepingTable}>
            <table className={styles.housekeepingTable}>
              <thead>
                <tr>
                  <th>Date</th>
                  {Array.from({ length: daysInMonth }, (_, i) => (
                    <th key={i}>{i + 1}</th>
                  ))}
                </tr>
                <tr>
                  <td>
                    <p>No. of Toilets</p>
                  </td>
                  {Array.from({ length: daysInMonth }, (_, i) => (
                    // <td key={i}>{attendanceData[i]?.noOfToilet || 0}</td>
                    <td key={i}>
                      {attendaceData1.find((a) => a.day === i + 1)?.noOfToilet}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>
                    <p>Signature</p>{" "}
                  </td>
                  {Array.from({ length: daysInMonth }, (_, i) => (
                    <td key={i}>
                      {attendaceData1.find((a) => a.day === i + 1)?.signature !== undefined ? (
                        attendaceData1.find((a) => a.day === i + 1)?.signature ? (
                          <TfiCheck />
                        ) : (
                          <TfiClose />
                        )
                      ) : (
                        ""
                      )}
                    </td>
                  ))}

                </tr>
              </thead>
            </table>
          </div>

          <div className={styles.PresentAbsent}>
            <div>
              <div className={styles.presenetText}>
                <TfiCheck />
                <p>Present</p>
              </div>
              <div className={styles.presenetText}>
                <TfiClose />
                <p>Absent</p>
              </div>
            </div>

            <div className={styles.AreaOfficer}>
              <p>Prepared By Area Officer</p>
            </div>

            <div className={styles.Principal}>
              <p>Approved By Principal/HOS</p>
            </div>
          </div>
        </div>


      </div>

    </>
  );
}

export default PrintedForm;
import React from 'react';
import styles from "./TableRow.module.css";

const TableRow = ({ daysInMonth, existingAttendanceData, handleChecked, isEditAdd }) => {
    return (
      <div className={styles.tableRow}>
        <div className={styles.tableCell}>Sign</div>
        {Array.from({ length: daysInMonth }, (_, i) => {
          const dayIndex = i + 1;
          const isDayDisabled = existingAttendanceData?.find((a) => a?.indexD === dayIndex);
  
          return (
            <div key={i} className={styles.tableCell}>
              <input
                id={`check-${i}`}
                type="checkbox"
                onChange={() => handleChecked(i)}
                checked={existingAttendanceData?.find((a) => a?.indexD === dayIndex)?.signature}
                disabled={isEditAdd ? !isDayDisabled : isDayDisabled}
              />
            </div>
          );
        })}
      </div>
    );
  };
  

export default TableRow;

import axios from "axios";
import React, { useEffect, useState } from "react";
import styles from "./Admin.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";




function Admin() {
  const [tableData, setTableData] = useState([]);
  const [inputDate, setInputDate] = useState(null);
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [schoolId, setSchoolId] = useState();
  const [token, setToken] = useState(localStorage.getItem("token") || '');

  const handleCalendarChange = (date) => {
    const selectedMonthYear = date ? date.toISOString().slice(0, 7) : null;
    setInputDate(selectedMonthYear);
    setCalendarOpen(false);
  };

  const handleCalendarIconClick = () => {
    setCalendarOpen(!isCalendarOpen);
  };
console.log(inputDate)
  const fetchData = async () => {
    try {
      console.log("Fetching data...", schoolId, inputDate, token);
      const response = await axios.get(
        `https://school-hygiene.codebucketstage.online/api/v1/attendance/grouped/all`,
        {
          params: { billingMonth: inputDate },
          headers: {
            token: token,
          },
        }
      );
      console.log("Response:", response);
      setTableData(response?.data.data);
      console.log(response.data.data)
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, [ schoolId,inputDate, token]);

 
  
 

  return (<>
    <div className={styles.mainContainer}>
      <h2 className={styles.textCenter}>Admin DashBoard</h2>
      {/* <div className={styles.InputGroup}> 
        
        <label>Enter School Id: </label>
        <input type="text" className="form-control" placeholder="Search School Id" onChange={(e)=>setSchoolId(e.target.value)}/>
      </div> */}

      <hr className={styles.HR} />
      <div className={styles.DatePick}>
        <div >
          <DatePicker
            onChange={handleCalendarChange}
            selected={inputDate ? new Date(inputDate) : null}
            dateFormat="yyyy/MM"
            showMonthYearPicker
            onFocus={() => setCalendarOpen(true)}
            onClickOutside={() => setCalendarOpen(false)}
            open={isCalendarOpen}
            placeholderText="Choose Year and Month"
            className={styles.datePicker}
            onClick={handleCalendarIconClick}
          />
        </div>
        <div>
          <FaCalendarAlt
            onClick={handleCalendarIconClick}
            style={{ cursor: "pointer" }}
            className={styles.calendarIcon}
          />
        </div>
        <div className={styles.ClearMonthBtn}>
         <button type="button" onClick={()=>setInputDate(null)}>Clear Month</button>
        </div>
       
      </div>
      <hr className={styles.HR} />

      <table className={styles.Table}>
        <thead>
          <tr>
            <th>SN</th>
            <th>School ID</th>
            <th>Total Days of  Checked Toilets</th>
            <th>Total No. of Toilets checked</th>
            <th>Total No. of Toilet count in Month</th>
          </tr>
        </thead>
        <tbody className={styles.TableBody}>
          {Array.isArray(tableData) && tableData.length > 0 ? (
            tableData.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data.schoolId}</td>
                <td>{data.signatureTrueCount}</td>
                <td>{data.recordCount}</td>
                <td>{data.totalToilets}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
      
     
    
    </div>
    <div className={styles.MobileContainerAdmin}>
   {
    tableData.map((data, index) => (
    <table border="1" key={index}>
        <thead>
          <tr>
            <th colSpan="3">{index+1}. School Id : {data.schoolId}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Total Days of Checked Toilets</td>
            <td>Total No. of Toilets checked</td>
            <td>Total No. of Toilet count in Month</td>
          </tr>
          <tr>
            <td>{data.signatureTrueCount}</td>
            <td>{data.recordCount}</td>
            <td>{data.totalToilets}</td>
          </tr>
        </tbody>
      </table>))}

<div>
  <hr />
  <p>Data not found</p>
  <hr />
</div>

      </div>
      
    </>
  );
}

export default Admin;

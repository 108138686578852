export const calculateCheckboxInfo = (index, updateCheck, existingData, newData) => {
    const dayIndex = index + 1;
    const isChecked = updateCheck ? existingData?.find((a) => a?.indexD === dayIndex)?.signature : newData?.find((a) => a?.indexD === dayIndex)?.signature;
    const isDisabled = updateCheck ? newData?.find((a) => a?.indexD === dayIndex) : existingData?.find((a) => a?.indexD === dayIndex);
    const isExistingTrueFound = existingData?.find((a) => a?.indexD === dayIndex)?.signature;
    const isExistingTrueFoundSome = existingData?.some((a) => a?.indexD === dayIndex);
  
    return {
      isChecked,
      isDisabled,
      isExistingTrueFound,
      isExistingTrueFoundSome,
    };
  };
import React, { useState, useEffect, useRef } from "react";
import styles from "./Billing.module.css";
import { FaCalendarAlt } from "react-icons/fa";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toast, { Toaster } from "react-hot-toast";
import PrintButton from "./PrintButton";
import { useNavigate } from "react-router-dom";
import { FcPrint } from "react-icons/fc";
import { SiPastebin } from "react-icons/si";
import TableRow from "./Rows/TableRow";
import { calculateCheckboxInfo } from "../utils/calculateCheckboxInfo";

const Billing = () => {

  const navigate = useNavigate()

  const currentMonthDays = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
  ).getDate();

  const [inputDate, setInputDate] = useState("");
  const [daysInMonth, setDaysInMonth] = useState(currentMonthDays);
  const calendarRef = useRef();
  const [schoolId, setSchoolId] = useState("");
  const [errorMonth, setErrorMonth] = useState(null);
  const [errorId, setErrorId] = useState(null);
  const [existingAttendanceData, setExistingAttendanceData] = useState([]);
  const [inputToilet, setInputToilet] = useState("")
  const [schoolData, setSchoolData] = useState({
    schoolName: "",
    districtName: "",
    blockName: "",
    attendance: [],
  });
  const [updateCheck, setUpdateCheck] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [selectedAttendance, setSelectedAttendance] = useState([])

  const initialAttendanceData = (date) => {
    const newAttendanceData = Array.from({ length: daysInMonth }, (_, i) => ({
      indexD: i + 1,
      date: `${date}-${(i + 1).toString().padStart(2, '0')}`,
      signature: false,
      noOfToilet: 0,
    }));
    setAttendanceData(newAttendanceData);
  };


  useEffect(() => {
    if (inputDate) {
      initialAttendanceData(inputDate);
      // setSelectedAttendance([])
      // setSelectedAttendance((prev)=> prev)
    }
  }, [inputDate]);

  useEffect(() => {
    setDaysInMonth(currentMonthDays);
  }, [currentMonthDays]);


  const handleCalendarChange = (date) => {
    // resetState();
    if (date === null) {
      return;
    }
    if (date === null) {
      setInputDate("");
      setDaysInMonth(0);
    } else {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const dateString = `${year}-${month.toString().padStart(2, "0")}`;
      setInputDate(dateString);
      const newDate = new Date(year, month - 1, 1);
      const newDaysInMonth = new Date(year, month, 0).getDate();
      setDaysInMonth(newDaysInMonth);
    }
  };



  const handleSelectedAttendance = (dayIndex, data) => {

    const foundItem = data.find((a) => a.indexD === dayIndex + 1) || {};

    if (foundItem) {
      const existingItemIndex = selectedAttendance.findIndex((item) => item.indexD === foundItem.indexD);

      setSelectedAttendance((prevData) => {
        if (existingItemIndex !== -1) {
          const newData = prevData.map((item) =>
            item.indexD === foundItem.indexD
              ? { ...item, signature: !item.signature }
              : { ...item }
          );
          return newData;
        } else {
          return [...prevData, { ...foundItem, signature: !foundItem.signature }];
        }
      });
    }

  };

  const handleChecked =  (dayIndex) => {

    if (!inputDate) {
      toast.error("Please select Billing Month");
      return;
    }
    if (!schoolId) {
      toast.error("Please enter School ID ");
      return;
    }

    handleSelectedAttendance(dayIndex, attendanceData)


    setAttendanceData((prevData) => {
      const newData = [...prevData];
      const dayOfMonth = dayIndex + 1;
      const formattedDay =
        dayOfMonth < 10 ? `0${dayOfMonth}` : dayOfMonth.toString();

      const updatedAttendance = {
        ...newData[dayIndex],
        signature: !newData[dayIndex]?.signature || false,
        date: `${inputDate}-${formattedDay}`,
      };

      newData[dayIndex] = updatedAttendance;
      return newData;
    });

  };


  const handleCheckedUpdate = (dayIndex) => {
    handleSelectedAttendance(dayIndex, existingAttendanceData)
    const foundItem = existingAttendanceData.find((a) => a.indexD === dayIndex + 1);

    if (foundItem) {
      setExistingAttendanceData((prevData) => {
        const newData = prevData.map((item) =>
          item.indexD === foundItem.indexD
            ? { ...item, signature: !item.signature || false }
            : item
        );

        return newData;
      });
    }
  };


  const handleChangeToilet = (value) => {
    setInputToilet(value)
  };


  const token = localStorage.getItem("token");

  const getSchoolData = async () => {
    try {
      const response = await axios.get(
        `https://school-hygiene.codebucketstage.online/api/v1/school/${schoolId}`
      );
      setSchoolData(response.data.data);
      setErrorMonth("");
      setErrorId("");
    } catch (error) {
      if (error.response && error.response.status === 204) {
        console.log("School data not found (404)");
        setErrorMonth("");
        setErrorId("");
      } else {
        toast.error("Enter Valid School Id")
        console.error("Error fetching school data:", error);
        // setSchoolData({
        //   schoolName: "",
        //   districtName: "",
        //   blockName: "",
        //   attendance: [],
        // })

        // initialAttendanceData(inputDate)
        // setSelectedAttendance([])
      }
    }
  };



  const getExistingData = async () => {
    try {
      const existingDataResponse = await axios.get(
        `https://school-hygiene.codebucketstage.online/api/v1/attendance/${schoolId}?billingMonth=${inputDate}`,
        {
          headers: {
            token: `${token}`,
          },
        }
      );


      if (existingDataResponse.data.status) {
        const data = existingDataResponse.data.data.attendance.map((a, i) => ({
          indexD: Number(a.day.split("-")[2]),
          date: a.day,
          signature: a.signature,
          noOfToilet: a.noOfToilet,
        }));
        setExistingAttendanceData(data);
      } else {
        setExistingAttendanceData([]);
        initialAttendanceData(inputDate);
        // setAttendanceData([]);
        setInputToilet("");
      }
    } catch (error) {
      if (error.response && error.response.status === 204) {
        toast.error("existing Attendance Not Found")
        setExistingAttendanceData([]);
        initialAttendanceData(inputDate);
        setAttendanceData([]);
        setInputToilet("");
      } else {
        console.error("Error fetching existing data:", error);
      }
    }
  };





  const getAttendance = async () => {
    try {
      if (!inputDate) {
        throw new Error("Please select Billing Month");
      }
      if (!schoolId) {
        throw new Error("Please enter School ID");
      }

      await getSchoolData()
      await getExistingData()

    } catch (error) {
      console.log(error)
    }
  };


  useEffect(() => {
    if (schoolId.length === 11) {
      getSchoolData();
    }
  }, [schoolId]);

  useEffect(() => {
    if (inputDate && schoolId.length === 11) {
      getExistingData();
    }
  }, [inputDate, schoolId]);

  const padWithZero = digit => (digit < 10 ? `0${digit}` : digit);

  useEffect(()=> {
      if(selectedAttendance){
       const _formattedDay = inputDate < 10 ? `0${inputDate}` : inputDate.toString();
        const _selectedAttendance = selectedAttendance.map((day, index) => ({  ...day, date: _formattedDay + "-" + (padWithZero(day.indexD)), noOfToilet: Number(inputToilet) }))
        setSelectedAttendance(_selectedAttendance)
      }
  }, [inputDate])


  const handleSubmitAdd = async (e) => {
    e.preventDefault();
    const checkedDays = selectedAttendance.map((day, index) => ({ date: inputDate + "-" + (index + 1), ...day, noOfToilet: Number(inputToilet) }))
      .filter((day) => day.signature);

    if (checkedDays.length === 0) {
      toast.error("Please Select Slots");
      return;
    }

    if (!inputDate) {
      toast.error("Please select Billing Month");
      return;
    }
    if (!schoolId) {
      toast.error("Please enter School ID ");
      return;
    }
    if (!inputToilet) {
      toast.error("Please enter Number of Toilet");
      return
    }


    const dataToAdd = {
      BillingMonth: inputDate,
      SchoolId: schoolId,
      attendance: checkedDays,
    };

    try {
      const response = await axios.post(
        "https://school-hygiene.codebucketstage.online/api/v1/attendance/create",
        dataToAdd
      );
      if (response.status === 200) {
        toast.success("Successfully submitted");
        setSelectedAttendance([]);
        await getExistingData();
      }
      if (
        response.data.skippedAttendance &&
        response.data.skippedAttendance.length > 0
      ) {

        console.log(response.data.skippedAttendance[0]);
      } else if (
        response.data.createdAttendance &&
        response.data.createdAttendance.length > 0
      ) {
        toast.success("Attendance processed");
      }
    } catch (error) {
      console.error("Error creating data:", error);
      toast.error("Error creating data");
    }
  };


  const handleCheckboxChange = async () => {
    if (!inputDate) {
      toast.error("Please select Billing Month");
      return;
    }
    if (!schoolId) {
      toast.error("Please enter School ID ");
      return;
    }

    setUpdateCheck(!updateCheck)

    if (!updateCheck) {
      await getExistingData()
    }
  }


  const handleSubmitUpdate = async () => {
    const checkedDays = selectedAttendance.map((day, index) => ({ date: inputDate + "-" + (index + 1), ...day, noOfToilet: Number(inputToilet) }))

    if (!inputDate) {
      toast.error("Please select Billing Month");
      return;
    }
    if (!schoolId) {
      toast.error("Please enter School ID ");
      return;
    }
    if (!inputToilet) {
      toast.error("Please enter Number of Toilet");
      return
    }

    if (checkedDays.length === 0) {
      toast.error("Please Select Date");
      return;
    }

    const dataToUpdate = {
      BillingMonth: inputDate,
      SchoolId: schoolId,
      attendance: checkedDays,
    };

    const response = await axios.put(
      "https://school-hygiene.codebucketstage.online/api/v1/attendance/update",
      dataToUpdate
    );
    if (response.status === 200) {
      setSelectedAttendance([])
      toast.success("Successfully updated");
    }
  }

  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const handleCalendarIconClick = () => {
    setCalendarOpen(!isCalendarOpen);
  };

  const handlePrint = () => {
    navigate('/print', { state: { schoolId, inputDate, navDaysInMonth:daysInMonth } });
  }


  const handlePaste = async () => {
    try {
      const text = await navigator.clipboard.readText();
      if (/^\d{11}$/.test(text)) {
        setSchoolId(text);
        toast.success('School ID pasted successfully.');
      } else {
        toast.error('Not a valid 11-digit school ID.');
      }
    } catch (error) {
      toast.error('Failed to read clipboard.');
    }
  };

  return (
    <>
      {" "}
      <Toaster />
      <div className={styles.formContainer}>
        <div className={styles.heading}>
          <h3 style={{ color: "black" }}>
            HOUSEKEEPING REPORT OF M/s CODEBUCKET SOLUTIONS PRIVATE LIMITED
          </h3>
        </div>
        <div className={styles.Calender}>
          <h4>Billing Month :</h4>
          <div className={styles.calendarIcon}>
            <div className={styles.calendarDropdown} ref={calendarRef}>
              <DatePicker
                onChange={handleCalendarChange}
                selected={inputDate ? new Date(inputDate) : null}
                dateFormat="yyyy/MM"
                showMonthYearPicker
                onFocus={() => setCalendarOpen(true)}
                onClickOutside={() => setCalendarOpen(false)}
                open={isCalendarOpen}
              />
              <FaCalendarAlt
                onClick={handleCalendarIconClick}
                style={{ cursor: "pointer" }}
                size={20}
              />
            </div>
          </div>

          {errorMonth ? (
            <span style={{ color: "red" }}>Please select Month and Year</span>
          ) : (
            ""
          )}
        </div>
        <div className={styles.Schoolid}>
          <h4>School ID : </h4>

          <div className={styles.container_input}>
            <input
              type="text"
              value={schoolId}
              onChange={(e) => setSchoolId(e.target.value)}
            // ref={schoolIdRef}
            >
            </input>
            <SiPastebin onClick={handlePaste} />
          </div>
          <button type="button" onClick={getAttendance}>
            Search
          </button>
          {errorId ? (
            <span style={{ color: "red" }}>Please Enter school Id</span>
          ) : (
            ""
          )}
        </div>
        <div className={styles.schoolname}>
          <h4>School Name : </h4>
          <span>{schoolData.schoolName}</span>
        </div>
        <div className={styles.DistBlock}>
          <h4>Distt. & Block : </h4>
          <span>
            {schoolData.districtName}, {schoolData.blockName}
          </span>
        </div>
        <div >
          <div className={styles.housekeepingTable}>
            <div className={styles.tableRow}>
              <div className={styles.tableHeader}>Date</div>
              {Array.from({ length: daysInMonth }, (_, i) => {
                const { isChecked, isDisabled, isExistingTrueFound, isExistingTrueFoundSome } = calculateCheckboxInfo(
                  i,
                  updateCheck,
                  existingAttendanceData,
                  attendanceData
                );

                return !updateCheck ? (
                  <label
                    htmlFor={`check-${i}`}
                    key={i}
                    className={`${styles.label}   ${isChecked ? styles.checkedLabelActive : styles.checkedLabelNotActive} ${(isDisabled && !updateCheck) ? styles.disabledLabel : ''}`}
                  >
                    {i + 1}
                  </label>
                ) : (
                  <label
                    htmlFor={`check-${i}`}
                    key={i}
                    // className={`${styles.label}   ${isChecked ? styles.checkedLabelActive : styles.uncheckedLablActive} ${(!isExistingAttendance && isDisabled) ? styles.disabledLabel : ''}`}
                    // className={`${styles.label} ${(!isExistingAttendance) ? styles.disabledLabel : styles.checkedLabelNotActive}`}
                    className={`${styles.label} ${isChecked ? styles.checkedLabelActive : styles.checkedLabelNotActive && styles.uncheckedLablActive} ${(!isExistingTrueFoundSome && !isExistingTrueFound) ? styles.disabledLabel : styles.checkedLabelNotActive}`}
                  >
                    {i + 1}
                  </label>
                );
              })}
            </div>

            {!updateCheck ? (
              <TableRow
                daysInMonth={daysInMonth}
                existingAttendanceData={existingAttendanceData}
                handleChecked={handleChecked}
                isEditAdd={updateCheck}
              />

            ) : (

              <TableRow
                daysInMonth={daysInMonth}
                existingAttendanceData={existingAttendanceData}
                handleChecked={handleCheckedUpdate}
                isEditAdd={updateCheck}
              />
            )}
          </div>

        </div>
        <div className={styles.NoToilets}>
          <p>No of Toilets :</p>
          <input
            type="number"
            min={1}
            value={inputToilet}
            onChange={(e) => handleChangeToilet(e.target.value)}
          />
        </div>
        <div className={styles.submitbtn}>
          {!updateCheck ? <button type="button" onClick={handleSubmitAdd}>
            Save
          </button>
            :
            <button type="button" onClick={handleSubmitUpdate} style={{ background: "#ffc107", color: "black" }}>
              update
            </button>}

        </div>
        <div onClick={handlePrint} className={styles.print_container}>
          {/* <PrintButton />  */}

          <div className={styles.button_withIcon}><FcPrint /><span>Print</span></div>
        </div>

        <div className={styles.UpdateCheck}>
          <span style={{ color: "red" }}>
            Check to update this attandence
          </span>
          <input
            type="checkbox"
            checked={updateCheck}

            onChange={handleCheckboxChange}
          />

        </div>

      </div>
    </>
  );
}

export default Billing;
